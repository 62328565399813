<template>
  <div>
    <v-dialog v-model="dialog" max-width="1024" scrollable>
      <!-- Titulo del Formulario -->
      <v-card>
        <v-card-title class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
          <div v-if="esNuevoCobro == 0">Cobro N° {{ cobroId }}</div>
          <div v-else>Nuevo Cobro Online</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <!-- Contenido -->
        <v-card-text>
          <v-col cols="12">
            <v-card flat width="auto">
              <v-card-text>
                <!-- Empresa, Sucursal, Punto de Venta y Operacion -->
                <v-row>
                  <v-col cols="12" sm="3" md="3" class="py-0">
                    Empresa
                    <v-autocomplete
                      v-model="selectedEmpresa"
                      item-text="nombre"
                      :items="empresas"
                      hide-details
                      outlined
                      dense
                      return-object
                      @change="set_locales()"
                      :disabled="esNuevoCobro == 0"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-0">
                    Sucursal
                    <v-autocomplete
                      v-model="selectedSucursal"
                      item-text="provincia"
                      :items="
                        sucursales.filter(
                          (suc) => suc.familia_id == selectedEmpresa.id
                        )
                      "
                      hide-details
                      outlined
                      dense
                      return-object
                      :disabled="esNuevoCobro == 0"
                    ></v-autocomplete>
                  </v-col>
                  <!-- Solicitud de Adhesion -->
                  <v-col cols="12" sm="3" md="3" class="py-0">
                    Subscripción
                    <v-text-field
                      v-model="solAdhesion"
                      outlined
                      dense
                      type="text"
                      :readonly="esNuevoCobro == 0"
                      :disabled="esNuevoCobro == 0"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- Importe, Financiacion, Importe por Cuota e Importe Total -->
                <v-row class="pt-6">
                  <v-col cols="12" sm="3" md="3" class="py-0">
                    Importe
                    <text-field-money
                      v-model="importe"
                      type="number"
                      v-bind:properties="{
                        prefix: '$',
                        outlined: true,
                        dense: true,
                        readonly: esNuevoCobro == 0,
                        disabled: esNuevoCobro == 0,
                      }"
                      @blur="getFinanciaciones()"
                    ></text-field-money>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-0">
                    Financiación
                    <v-row class="mt-0">
                      <v-autocomplete
                        v-model="selectedFinanciacion"
                        item-text="descripcion"
                        :items="financiaciones"
                        hide-details
                        outlined
                        dense
                        return-object
                        :disabled="esNuevoCobro == 0"
                      ></v-autocomplete>
                      <v-btn
                        icon
                        small
                        title="Buscar Financiaciones"
                        @click="buscarFinanciaciones()"
                        class="ml-2"
                        :disabled="esNuevoCobro == 0"
                        ><v-icon color="success" small
                          >fas fa-search</v-icon
                        ></v-btn
                      >
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="py-0">
                    Importe por Cuota
                    <text-field-money
                      v-model="importeCuota"
                      type="number"
                      readonly
                      v-bind:properties="{
                        prefix: '$',
                        outlined: true,
                        dense: true,
                        filled: true,
                        readonly: true,
                      }"
                    ></text-field-money>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-0">
                    Importe Total
                    <text-field-money
                      v-model="importeTotal"
                      outlined
                      dense
                      type="number"
                      readonly
                      v-bind:properties="{
                        prefix: '$',
                        outlined: true,
                        dense: true,
                        filled: true,
                      }"
                    ></text-field-money>
                  </v-col>
                </v-row>

                <!-- Datos de la Tarjeta -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12">
                    <v-card elevation="2" outlined>
                      <v-card-title> Datos de la Tarjeta </v-card-title>
                      <v-card-text>
                        <!-- Tarjeta, Cuotas, Titular y DNI -->
                        <v-row>
                          <v-col cols="12" sm="3" md="3" class="py-0">
                            Tarjeta
                            <v-autocomplete
                              v-model="selectedTarjeta"
                              item-text="tarjeta_nombre"
                              :items="tarjetas"
                              hide-details
                              outlined
                              dense
                              return-object
                              :disabled="lockTarjeta"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="2" md="2" class="py-0">
                            Cuotas
                            <v-text-field
                              v-model="cuotas"
                              outlined
                              dense
                              type="number"
                              :readonly="lockCuotas"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="5" md="5" class="py-0">
                            Titular
                            <v-text-field
                              v-model="titular"
                              outlined
                              dense
                              type="text"
                              :readonly="esNuevoCobro == 0"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2" md="2" class="py-0">
                            DNI
                            <v-text-field
                              v-model="documentoNro"
                              outlined
                              dense
                              type="number"
                              :readonly="esNuevoCobro == 0"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <!-- Tarjeta Número, Vencimiento y Cuotas -->
                        <v-row>
                          <v-col cols="12" sm="3" md="3" class="py-0">
                            Tarjeta Numero
                            <the-mask
                              v-model="tarjetaNumero"
                              :mask="[
                                '####-####-####-####',
                                '####-####-####-###',
                                '####-####-#####-####',
                                '####-####-####-####-###',
                              ]"
                              :class="
                                $vuetify.theme.dark
                                  ? 'white--text tarjetaCss'
                                  : 'black--text tarjetaCss'
                              "
                              outlined
                              :readonly="esNuevoCobro == 0"
                              :disabled="esNuevoCobro == 0"
                            ></the-mask>
                          </v-col>
                          <v-col cols="12" sm="2" md="2" class="py-0">
                            Vencimiento
                            <the-mask
                              v-model="vencimiento"
                              mask="##/##"
                              :class="
                                $vuetify.theme.dark
                                  ? 'white--text tarjetaCss'
                                  : 'black--text tarjetaCss'
                              "
                            ></the-mask>
                          </v-col>
                          <v-col sm="2" md="2" class="py-0">
                            CVC
                            <v-text-field
                              v-model="cvc"
                              outlined
                              dense
                              type="number"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-row justify="end">
                          <v-col cols="2">
                            <label
                              ><h3><strong>Total</strong></h3></label
                            >
                            <label class="text-primary"
                              ><h3>
                                <strong
                                  >$
                                  {{
                                    Intl.NumberFormat("de-DE").format(
                                      importeTotal
                                    )
                                  }}</strong
                                >
                              </h3>
                            </label>
                          </v-col>
                        </v-row>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
              <!-- Botones -->
              <v-card-actions class="d-flex justify-center mx-2">
                <v-btn color="warning" @click="dialog = false" class="ma-2"
                  >Volver</v-btn
                >
                <BtnConfirmar
                  @action="cobrar()"
                  color="success"
                  icono="fas fa-credit-card"
                  :texto="`¿Esta seguro de realizar el Cobro?`"
                  nombre="Cobrar"
                />
              </v-card-actions>
            </v-card>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Modal Seleccionar Financiacion -->
    <ModalSelectFP
      :datos="modalFP"
      @setFP="setearFP"
      @setearModalFps="setModalFp"
    />
  </div>
</template>

<script>
import { convertDecimals, order_list_by } from "../../util/utils";
import BtnConfirmar from "../util/BtnConfirmar.vue";
import { mapState } from "vuex";
import ModalSelectFP from "./ModalSelectFP.vue";
import TextFieldMoney from "../../components/util/TextFieldMoney.vue";
import { pdfCobroOnline } from "../../util/plantillas/pdfs";

export default {
  data() {
    return {
      cobro: {},
      cobroId: 0,
      tipoPedido: 0,
      terminaCarga: 0,
      empresas: [],
      selectedEmpresa: {},
      selectedSucursal: {},
      ptosVta: [],
      ptosVtaAux: [],
      operaciones: [],
      operacionesAux: [],
      selectedFinanciacion: {},
      financiaciones: [],
      importe: null,
      importeCuota: null,
      importeTotal: null,
      selectedTarjeta: {},
      tarjetas: [],
      cuotas: "",
      titular: "",
      documentoNro: "",
      tarjetaNumero: "",
      vencimiento: "",
      cvc: "",
      solAdhesion: "",
      estados: [],
      modalFP: {
        activo: false,
        financiaciones: [],
      },
      lockTarjeta: true,
      lockCuotas: true,
      pdf: null,
    };
  },

  created() {
    this.initForm();
  },

  methods: {
    async initForm() {
      this.$store.state.loading = true;
      this.empresas = JSON.parse(localStorage.getItem("familias"));
      let operacionesPeticion = await this.$store.dispatch(
        "cobros/init_cobros",
        {
          nuevo: this.esNuevoCobro,
          id: this.cobroId,
          tipo_pedido: this.tipoPedido,
        }
      );
      this.$store.state.loading = false;
      if (operacionesPeticion.resultado == 1) {
        this.operacionesAux = operacionesPeticion.operaciones;
        this.estados = operacionesPeticion.estados;
        this.ptosVtaAux = operacionesPeticion.locales;
        this.tarjetas = operacionesPeticion.tarjetas;
        if (this.esNuevoCobro == 0) {
          this.initCobro(operacionesPeticion.cobro, operacionesPeticion.fps);
        } else {
          this.terminaCarga = 1;
        }
      } else {
        this.$store.dispatch("show_snackbar", {
          text: operacionesPeticion.msj,
          color: "error",
        });
      }
    },

    async initCobro(cobro, fps) {
      // guardo el cobro
      this.cobro = cobro;
      // titular
      this.titular = cobro.titular;
      // DNI
      this.documentoNro = cobro.documento_numero;
      // tarjeta
      this.tarjetaNumero = cobro.tarjeta_numero;
      // empresa
      this.selectedEmpresa = this.empresas.filter(
        (element) => element.empresa_ora == cobro.empresa_codigo
      )[0];
      // sucursal
      this.selectedSucursal = await this.sucursales.filter(
        (element) => element.sucursal_ora == cobro.sucursal_codigo
      )[0];
      this.importe = convertDecimals(cobro.importe || 0);
      // financiacion
      this.financiaciones = fps;

      let unFp = this.financiaciones.filter(
        (element) => element.codigo == cobro.fp_cond_id
      );
      if (unFp.length > 0) {
        this.selectedFinanciacion = unFp[0];
      } else {
        this.selectedFinanciacion = {};
      }
      // solicitud de adhesion
      if (
        cobro.solicitud_adhesion != null &&
        cobro.solicitud_adhesion != undefined &&
        cobro.solicitud_adhesion.toString().length > 0
      ) {
        this.solAdhesion = cobro.solicitud_adhesion;
      }
      // tarjeta
      let unaTarjeta = this.tarjetas.filter(
        (element) => element.tarjeta_codigo == cobro.tarjeta_codigo
      );
      if (unaTarjeta.length > 0) {
        this.selectedTarjeta = unaTarjeta[0];
      } else {
        this.lockTarjeta = false;
      }
      // cuotas
      if (
        cobro.cuotas != null &&
        cobro.cuotas != undefined &&
        cobro.cuotas.toString().length > 0
      ) {
        this.cuotas = cobro.cuotas;
      } else {
        this.lockCuotas = false;
      }
    },

    set_locales() {
      let sucursal = this.sucursales.filter(
        (sucursal) => sucursal.familia_id === this.selectedEmpresa.id
      );
      this.selectedSucursal = sucursal.length == 1 ? sucursal[0] : null;
      this.importe = null;
      this.selectedFinanciacion = {};
    },

    validoEnc() {
      if (Object.keys(this.selectedEmpresa).length == 0)
        return { valido: false, msj: "Debe seleccionar la Empresa." };
      if (Object.keys(this.selectedSucursal).length == 0)
        return { valido: false, msj: "Debe seleccionar la Sucursal." };
      if (
        this.solAdhesion == null ||
        this.solAdhesion == undefined ||
        this.solAdhesion.length == 0
      ) {
        return { valido: false, msj: "Debe tipear una solicitud de adhesión." };
      }
      if (
        this.importe == null ||
        this.importe == undefined ||
        this.importe.toString().length == 0
      )
        return { valido: false, msj: "Importe inválido." };
      if (convertDecimals(this.importe) <= 0)
        return { valido: false, msj: "Importe inválido." };
      return { valido: true, msj: "OK" };
    },

    validar(valor, mensaje) {
      return valor && valor.toString().length
        ? { valido: true, msj: "OK" }
        : { valido: false, msj: mensaje };
    },

    validarCobro() {
      return this.validoEnc().valido
        ? [
            [this.importeCuota, "Importe de la Cuota sin definir."],
            [this.importeTotal, "Importe Total sin definir."],
            [
              Object.keys(this.selectedTarjeta).length,
              "Debe seleccionar la tarjeta.",
            ],
            [this.cuotas, "Cantidad de Cuotas sin definir."],
            [this.titular, "Debe ingresar el Titular de la Tarjeta."],
            [this.documentoNro, "Debe ingresar el Nro de Documento."],
            [this.tarjetaNumero, "Debe ingresar el Nro de Tarjeta."],
            [
              this.vencimiento && this.vencimiento.toString().length == 4,
              "Debe ingresar el Vencimiento de la Tarjeta.",
            ],
            [this.cvc, "CVC sin definir."],
          ]
            .map(([valor, mensaje]) => this.validar(valor, mensaje))
            .find((elemento) => !elemento.valido) || { valido: true, msj: "OK" }
        : this.validoEnc();
    },

    async getFinanciaciones() {
      if (
        this.importe != null &&
        this.importe != undefined &&
        this.importe.toString().length > 0 &&
        this.esNuevoCobro == 1
      ) {
        this.financiaciones = [];
        this.selectedFinanciacion = {};
        // valido los datos del encabezado
        let validosEnc = this.validoEnc();
        if (validosEnc.valido == false) {
          this.$store.dispatch("show_snackbar", {
            text: validosEnc.msj,
            color: "warning",
          });
          return;
        }
        this.$store.state.loading = true;
        // tipo de venta
        let tipo_venta = "OTROS";
        if (
          this.selectedEmpresa.operacion_cobro_ora != null &&
          this.selectedEmpresa.operacion_cobro_ora != undefined &&
          this.selectedEmpresa.operacion_cobro_ora != "null" &&
          this.selectedEmpresa.operacion_cobro_ora.toString().length > 0
        ) {
          tipo_venta = this.selectedEmpresa.operacion_cobro_ora;
        }
        // armo mi body
        let body = {
          importe: this.importe,
          sucursal: this.selectedSucursal.sucursal_ora,
          tipo_venta: tipo_venta,
          manual: 0,
          empresa: this.selectedEmpresa.empresa_ora,
          canal: 1,
          pto_vta: this.selectedSucursal.ptovta_ora,
          articulos: [],
        };
        let fpPeticion = await this.$store.dispatch(
          "cobros/get_financiaciones_habilitadas",
          body
        );
        this.$store.state.loading = false;
        if (fpPeticion.resultado == 1) {
          this.financiaciones = fpPeticion.fps;
          order_list_by(this.financiaciones, "descripcion");
        } else {
          this.$store.dispatch("show_snackbar", {
            text: operacionesPeticion.msj,
            color: "error",
          });
        }
      }
    },

    limpiarDatosTarjeta() {
      if (this.terminaCarga == 1) {
        this.selectedTarjeta = {};
        this.cuotas = "";
        this.titular = "";
        this.documentoNro = "";
        this.tarjetaNumero = "";
        this.vencimiento = "";
        this.cvc = "";
      }
    },

    setDatosFinanciacion() {
      this.importeCuota = 0;
      this.importeTotal = 0;
      if (this.esNuevoCobro == 0 && this.terminaCarga == 0) {
        this.terminaCarga = 1;
      } else {
      }
      if (Object.keys(this.selectedFinanciacion).length > 0) {
        this.importeTotal = convertDecimals(
          this.selectedFinanciacion.total_final
        );
        let unaTarjeta = this.tarjetas.filter(
          (element) =>
            element.tarjeta_codigo == this.selectedFinanciacion.tarjeta_codigo
        );
        if (unaTarjeta.length > 0) {
          this.selectedTarjeta = unaTarjeta[0];
          this.lockTarjeta = true;
        } else {
          // permito ingresar la tarjeta
          this.lockTarjeta = false;
        }
        let cuotas_desde = parseInt(this.selectedFinanciacion.cuotas_desde);
        let cuotas_hasta = parseInt(this.selectedFinanciacion.cuotas_hasta);
        let cuota_interna = parseInt(this.selectedFinanciacion.cuota_interna);
        let dif = cuotas_hasta - cuotas_desde;
        if (dif == 0) {
          this.importeCuota = convertDecimals(
            convertDecimals(this.selectedFinanciacion.total_final) /
              cuotas_hasta
          );
          this.cuotas = cuota_interna;
          this.lockCuotas = true;
        } else {
          // permito ingresar la cuota
          this.lockCuotas = false;
        }
      } else {
        this.importeCuota = null;
        this.importeTotal = null;
      }
    },

    buscarFinanciaciones() {
      let validosEnc = this.validoEnc();
      if (validosEnc.valido == false) {
        this.$store.dispatch("show_snackbar", {
          text: validosEnc.msj,
          color: "warning",
        });
        return;
      }
      if (this.financiaciones.length == 0) {
        this.$store.dispatch("show_snackbar", {
          text: "No se obtuvieron las financiaciones.",
          color: "info",
        });
        return;
      }
      this.modalFP.financiaciones = this.financiaciones;
      this.modalFP.activo = true;
    },

    async cobrar() {
      let cobroValido = this.validarCobro();
      if (cobroValido.valido == false) {
        this.$store.dispatch("show_snackbar", {
          text: cobroValido.msj,
          color: "warning",
        });
        return;
      }
      // obtengo año y mes de vencimiento
      let mes = this.vencimiento.substring(0, 2);
      let anio = this.vencimiento.substring(2, 4);
      // controlo el mes
      if (parseInt(mes) < 1 || parseInt(mes) > 12) {
        this.$store.dispatch("show_snackbar", {
          text: "Mes de Vencimiento no válido.",
          color: "warning",
        });
        return;
      }
      this.$store.state.loading = true;
      // total a pagar
      let totalAPagar = "0";
      if (convertDecimals(this.importeTotal) % 1 == 0) {
        totalAPagar = parseInt(this.importeTotal).toString() + "00";
      } else {
        totalAPagar = convertDecimals(this.importeTotal) * 100;
        totalAPagar = parseInt(totalAPagar).toString();
      }
      // armo mi body para el cobro
      let body = {
        tarjeta_numero: this.tarjetaNumero.toString().replace("-", ""),
        mes_expira: mes.toString(),
        anio_expira: anio.toString(),
        codigo_seguridad: this.cvc.toString(),
        titular: this.titular,
        tipo_doc: "dni",
        documento: this.documentoNro.toString(),
        empresa_id: this.selectedEmpresa.empresa_ora,
        tipo_pedido: this.selectedEmpresa.operacion_cobro_ora,
        importe: totalAPagar,
        cuotas: this.cuotas.toString(),
        tarjeta_codigo: this.selectedTarjeta.tarjeta_codigo.toString(),
        pto_vta_codigo: this.selectedSucursal.ptovta_ora,
        fp_cond_id: this.selectedFinanciacion.codigo,
        sucursal_codigo: this.selectedSucursal.sucursal_ora,
        key_boton: "",
        solicitud_adhesion: this.solAdhesion,
      };
      // agrego la solicitud de adhesion?
      if (this.selectedEmpresa.empresa_ora == 3) {
        body.solicitud_adhesion = this.solAdhesion;
      }
      // es para reprocesar?
      if (this.esNuevoCobro == 0) {
        body.id = this.cobro.id;
      }
      // Cobrar
      let cobroPeticion = await this.$store.dispatch(
        "cobros/process_payment",
        body
      );
      this.$store.state.loading = false;
      // controlo la salida
      if (cobroPeticion.resultado == 1) {
        // llamada correcta a la API, se cobro?
        if (
          cobroPeticion.datosCobro != null &&
          cobroPeticion.datosCobro.hasOwnProperty("body") &&
          cobroPeticion.datosCobro.body.hasOwnProperty("status") &&
          cobroPeticion.datosCobro.body.status.toUpperCase() == "APPROVED"
        ) {
          // cobro exitoso!
          this.$store.dispatch("show_snackbar", {
            text: "Su Cobro fue procesado con Éxito.",
            color: "success",
          });
          // Traigo los datos para armar el pdf
          
          const pdfData = await this.$store.dispatch('cobros/get_pdf_cobro_data', {id: cobroPeticion.datosCobro.solicitud_id})

          let pdfPeticion = await pdfCobroOnline(pdfData.cobro);
          if (pdfPeticion.resultado == 1) {
            this.pdf = pdfPeticion.pdf;
            // Transformo de binarios a archivo
            const blob = new Blob([pdfPeticion.pdf], {
              type: "application/pdf",
            });

            // creo la url para descargarlo
            const blobUrl = URL.createObjectURL(blob);

            // creo link implicito para descargar automaticamente
            const downloadLink = document.createElement("a");
            downloadLink.href = blobUrl;
            downloadLink.setAttribute("download", `ReciboCobroOnline - ${cobroPeticion.datosCobro.pedido_numero}`);
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();

            // limpio la url y el evento 
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(blobUrl);
          } else {
            this.$store.dispatch("show_snackbar", {
              text: pdfPeticion.msj,
              color: "error",
            });
          }
          this.dialog = false;
        } else {
          // no se aprobó el cobro
          let msjError = "";
          if (
            cobroPeticion.datosCobro.hasOwnProperty("body") &&
            cobroPeticion.datosCobro.body.hasOwnProperty("status_details") &&
            cobroPeticion.datosCobro.body.status_details.hasOwnProperty(
              "error"
            ) &&
            cobroPeticion.datosCobro.body.status_details.error.hasOwnProperty(
              "reason"
            ) &&
            cobroPeticion.datosCobro.body.status_details.error.reason.hasOwnProperty(
              "description"
            )
          ) {
            msjError =
              cobroPeticion.datosCobro.body.status_details.error.reason
                .description;
          } else if (
            Object.keys(cobroPeticion.datosCobro).length == 3 &&
            cobroPeticion.datosCobro.hasOwnProperty("error") &&
            cobroPeticion.datosCobro.hasOwnProperty("exito") &&
            cobroPeticion.datosCobro.hasOwnProperty("mensaje") &&
            cobroPeticion.datosCobro.error == 1
          ) {
            msjError = cobroPeticion.datosCobro.mensaje;
          } else if (
            cobroPeticion.datosCobro.hasOwnProperty("error") &&
            cobroPeticion.datosCobro.error.hasOwnProperty("mensaje")
          ) {
            msjError = cobroPeticion.datosCobro.error.mensaje;
          } else {
            msjError = "Error genérico. No se pudo efectuar su cobro.";
          }
          this.$store.dispatch("show_snackbar", {
            text: msjError,
            color: "error",
          });
        }
      } else {
        // fallo el cobro
        this.$store.dispatch("show_snackbar", {
          text: cobroPeticion.msj,
          color: "error",
        });
      }
    },

    setModalFp(value) {
      this.modalFP.activo = value;
    },
    setearFP(item) {
      this.selectedFinanciacion = item;
    },
  },

  watch: {
    financiaciones: function () {
      if (this.financiaciones.length > 0) {
        for (let id in this.financiaciones) {
          if (
            this.financiaciones[id].cuotas_desde ==
            this.financiaciones[id].cuotas_hasta
          ) {
            this.financiaciones[id].importe_cuotas = convertDecimals(
              convertDecimals(this.financiaciones[id].total_final) /
                convertDecimals(this.financiaciones[id].cuotas_hasta)
            );
          }
        }
      }
    },
    selectedFinanciacion: function () {
      this.setDatosFinanciacion();
    },

    async dialog(val) {
      if (val && this.esNuevoCobro == 1) {
        this.selectedTarjeta = {};
        this.cuotas = "";
        this.titular = "";
        this.documentoNro = "";
        this.vencimiento = "";
        this.cvc = "";
        this.selectedEmpresa = {};
        this.selectedSucursal = {};
        this.selectedFinanciacion = {};
        this.financiaciones = [];
        this.importe = null;
        this.solAdhesion = "";
      }
      if (val && this.esNuevoCobro == 0) {
        await new Promise((resolve) => setTimeout(resolve, 1));
        this.cobroId = this.reprocesarCobro.pedido_numero;
        this.tipoPedido = this.reprocesarCobro.tipo_pedido;
        this.initForm();
      } else {
        this.selectedTarjeta = {};
        this.cuotas = "";
        this.titular = "";
        this.documentoNro = "";
        this.tarjetaNumero = "";
        this.vencimiento = "";
        this.cvc = "";
        this.selectedEmpresa = {};
        this.selectedSucursal = {};
        this.selectedFinanciacion = {};
        this.financiaciones = [];
        this.importe = null;
        this.solAdhesion = "";
      }
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    ...mapState("genericos", ["sucursales"]),
  },
  props: {
    value: Boolean,
    reprocesarCobro: Object,
    esNuevoCobro: Number,
  },
  components: { BtnConfirmar, ModalSelectFP, TextFieldMoney },
};
</script>

<style scoped>
.tarjetaCss {
  width: 100%;
  height: 32px;
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  padding: 5px 10px;
  background: none;
}
</style>
